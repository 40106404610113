.promotionDrawerRoot {
  .promotionRoot {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    margin-bottom: 0;
    &>._directionRow {
      border-bottom: 1px solid silver;
    }
  }
  .removeBtn {
    margin-right: 48px;
  }
}