.categoriesHierarchyRoot {
  .ag-cell-expandable {
    .ag-group-value{
      width: 100%;
    }
    // @TODO check if can be implemented on 'arrow' component level
    .ag-group-contracted, .ag-group-child-count, .ag-group-expanded {
      padding-bottom: 7px;
    }
  }
  .hierarchyGroupCell {
    display: flex;
    justify-content: space-between;
    .name {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .fourRow {
    display: flex;
    margin-left: 5px;
    margin-right: 15px;
    margin-top: 12px;
    &>div {
      flex-shrink: 0;
      width: 25%;
      padding-right: 16px;
      .button {
        float: right;
      }
    }
  }
  .overLay {
    display: grid;
  }
  .overLay > * {
    grid-column-start: 1;
    grid-row-start: 1;
  }
}