.reportedFramesSummaryRoot{
  .reasonText {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .reporterAndFix {
    display: flex;
    justify-content: space-between;
    .reporterText {
      flex: 1 1 auto;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .fixLink {
      flex: 0 0 auto;
      width: 40px;
    }
  }
}