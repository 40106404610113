@import 'src/styles/shared';

.productIssuesDisplayRoot {
  color: $mpm-red;
  .title {
    display: flex;
    font-family: $font-family-bold;
  }
  ul {
    padding-left: 16px;
    li {
      list-style-type: '- ';
    }
  }
}