.advAreasIndexRoot {
  flex:1;
  min-width: 400px;
  .paperX{
    overflow: hidden;
  }
  .advAreasListContainer {
    margin-top: 8px;
    overflow: scroll;
    height: calc(100% - 30px);
  }
}