.BPCODetailsRoot {
    .supplierRow {
        align-items: center;
    }
    .newBrandWrapper {
        margin-bottom: 24px;
        .newBrandBtn {
            margin-right: 8px;
        }
    }
}