@import 'src/styles/shared';

.dictionaryElementRoot {
  .dictionaryElementHeader {
    display: flex;
    &>div{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 16px;
      &>* {
        height: 50%;
      }
      &>*:last-child {
        display: flex;
        align-items: center;
      }
    }
    .checkboxContainer {
      margin-left: -10px;
    }
  }
  .validationInfo {
    color: $mpm-plum1;
    font-size: 0.9em;
  }
  .validationInfo::before {
    content: '* ';
  }
}