@import 'src/styles/shared';

.masterDataRoot {
  .categoryPanel {
    background: white;
    border-radius: 8px;
    margin: 8px;
    .categoryHeader {
      border-bottom: 1px solid $mpm-red;
      color: $mpm-red;
      padding: 8px;
      font-size: 1.3em;
    }
    .categoryItems {
      padding: 8px;
      height: 100%;
      width: 100%;
      color: $mpm-plum1;
    }
  }
}