.productBulkDescriptionDialogRoot {
  .MuiDialogTitle-root {
    padding: 4px 4px 0 4px;
    font-size: inherit;
  }
  .dialogContentWrapper {
    height: 100%;
    overflow: hidden;
    display: flex;
  }
  .withExplanation {
    display: flex;
    justify-content: space-between;
  }
}