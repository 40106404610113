@import "src/styles/shared";

.promotionsTableRoot {
  .generalInfoCell {
    .attributeName {
      display: none;
    }
    .generalInfoDisplayRoot {
      display: inline;
    }
    .displayChip.derived {
      background-color: $_light;
    }
  }
}