@import 'src/styles/shared';

.paperXRoot {
  padding: 4px;
  .paperX {
    padding: 8px;
    height: 100%;
    width: 100%;
    background: white;
    border-radius: 8px;
    color: $mpm-plum1;
  }
  &._scrollY {
    .paperX {
      overflow-y: scroll;
    }
  }
  &._fullTable .paperX{
    padding: 0;
  }
}
.paperXRoot.weldBottom {
  padding-bottom: 0;
  .paperX {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.paperXRoot.weldTop {
  padding-top: 0;
  .paperX {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}