@import 'src/styles/shared';

.framePreviewRoot {
  height: 100%;
  width: 100%;
  &.withBackground {
    background: $ice-blue-1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.withZoom {
    cursor: zoom-in;
  }
  .image {
    max-width: 100%;
    max-height: 100%;
  }
}