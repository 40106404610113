.productsListFiltersRoot{
  .filtersColumn{
    width: 310px;
    .paperX{
      width: 300px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      .filtersContainer{
        flex: 1;
        overflow-y: scroll;
      }
    }
  }
}