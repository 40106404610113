@import 'src/styles/shared';

.promotionSuggestionItemRoot .divider {
  border-top: 1px solid $mpm-red;
  margin: 8px -8px;
}
.underline {
   text-decoration: underline;
  padding: 0 6px;
  margin-bottom: 0;
  margin-top: 4px;
}