@import 'src/styles/shared';

.mediaPreviewRoot {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .transcriptionReadContainer {
    height: 100%;
    width: 100%;
    border-right: 1px solid $ice-blue-1;
    padding: 8px 16px;
    .transcriptValue {
        width: 100%;
        height: 85%;
        overflow: auto;
        text-overflow: clip;
        white-space: pre-wrap;
    }
  }
  .loadingOverlayContainer {
    position: static;
  }
}