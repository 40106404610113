@import 'src/styles/shared';

.branchesSelectionDialogRoot, .advertisingAreaDialogRoot {
  .MuiDialogContent-root {
    background: $mpm-blue;
    padding: 0;
  }
  .MuiDialogActions-root {
    padding: 5px;
  }
  .dialogFooter {
    display: flex;
    justify-content: space-between;
    .leftBtn {
      margin-right: auto;
    }
  }
}