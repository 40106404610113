@import 'src/styles/shared';

.keyRetailerBannerHierarchyRoot {
      .hierarchyLevelContainer {
        width: 33%;
        ._header {
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .listContainer {
          overflow: auto;
          .hierarchyItem {
            height: 42px;
            padding: 4px;
            border-radius: 4px;
            cursor: pointer;
            border: 1px solid white;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .actionButtonContainer a {
              margin-right: 8px;
            }
            button {
              border-color: white;
              color: white;
            }
          }
          .hierarchyItem:hover, .hierarchyItem.selected {
            background: $mpm-blue;
            color: $mpm-plum1;
            button {
              border-color: $mpm-plum1;
              color: $mpm-plum1;
            }
            span {
              color: $mpm-plum1;
            }
          }
        }
      }
      .hierarchyLevelContainer:last-child {
        width: 34%;
      }
}