@import 'src/styles/shared';

.masterDataCard_root {
  padding: 8px;
  border-radius: 0;
  min-width: 300px;
  min-height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px;
  border: 0;
  font-size: 1.4em;
  background: white;
  &:hover {
    background: lighten($_secondary-light, 70%);
    transition: all 0.3s;
  }
}