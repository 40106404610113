.promotionsOverviewDetailsRowRoot {
  background: white;
  display: flex;
  flex-direction: row;
  .previewContainer {
    height: 300px;
    width: 33%;
  }
  .actions {
    display: flex;
    padding: 8px;
    align-items: center;
  }
}