.spotActionRoot {
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
    a {
      display: flex;
      align-items: center;
      margin-left: 8px;
    }
  }