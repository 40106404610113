@import 'src/styles/shared';

.leafletProgressDialogRoot {
    .leafletProgressTitle {
        font-size: 16px;
        font-family: $font-family-bold;
        line-height: 1.5;
    }
    .leafletProgressList li {
        line-height: 1.8;
    }
    .currentUsersList {
        display: inline;
    }
    a {
        margin-right: 8px;
    }
}