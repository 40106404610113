@import 'src/styles/shared';

.attributeErrorRoot, .attributeLoadingRoot {
  height: 56px;
  border: 1px solid silver;
  padding: 9px;
  color: silver;
  border-radius: 4px;
  line-height: 34px;
  display: flex;
  align-items: center;
  svg {
    color: $mpm-red;
  }
}