@import 'src/styles/shared';
.branchDetailsRoot {
  .branchDetailsLabel {
    font-family: $font-family-bold;
  }
  .textField {
    padding-bottom: 16px;
  }
  ul {
    min-height: 32px;
    li {
      display: flex;
      padding-left: 32px;
      .icon {
        padding-left: 8px;
        color: $_secondary;
      }
    }
  }
}