@import 'src/styles/shared';

.frameDescriptionRoot {
  .leftCol {
    width: 33%;
  }
  .leafletProgressRoot {
    display: flex;
    flex-direction: column;
    .leafletProgressTitle {
      display: none;
    }
    .leafletProgressList {
      display: flex;
      justify-content: space-between;
    }
  }
  .advertisementContainer {
    width: 67%;
  }
  .detailsPanel {
    min-height: 300px;
  }
  .frameValidity {
    padding-top: 8px;
    padding-left: 0;
    .btn {
      position: relative;
      color: $mpm-plum1;
      top: -8px;
      left: 4px;
    }
    .btn:disabled {
      color: $disabledGrey;
    }
  }
  .countryInputContainer {
    display: inline-block;
    vertical-align: middle;
    .MuiSelect-select { // squize it to fit footer better
      padding: 6px;
    }
  }
  .filtersContainer {
    display: flex;
    .productSearchRoot {
      width: 100%;
    }
  }
}
.frameDescriptionDrawerRoot {
  background: rgba(0, 0, 0, 0.8);
  .MuiDrawer-paper {
    background: $mpm-blue;
    width: 67%;
  }
}
.frameDescriptionDrawerRoot.promotionDrawerRoot {
  background: none;
}