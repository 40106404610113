@import 'src/styles/shared';

.MuiAutocomplete-listbox {
  & li.Mui-focused {
      background: $mpm-blue!important;
    }
}

.mpmSearchRoot {
  position: relative;
  .searchOption.selected{
    background-color: lighten($mpm-red, 50%);
    color: $mpm-red;
  }
}

.mpmSearchOption {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 4px;
  cursor: pointer;
  &.noJustify {
    flex-direction: row;
    justify-content: flex-start;
    span {
      flex-shrink: 0;
    }
  }
  .categoryArrow {
    display: flex;
    align-content: center;
  }
}