.descriptionsFilterRoot {
  display: flex;
  &>div {
    flex: 1;
  }
  .left {
    margin-right: 8px;
  }
  .mid {
    margin-left: 8px;
    margin-right: 8px;
    &.simple {
      display: flex;
      justify-items: stretch;
      align-items: center;

      &.stretch > div {
        width: 100%;

        & > div {
          width: 100%;
        }
      }
    }
  }
  .right {
    margin-left: 8px;
    &.centered {
      display: flex;
      align-items: center;
    }
  }
}