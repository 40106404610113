/* ==========================================================================
FONT
========================================================================== */

$font-family-regular: 'Gilroy-Regular', 'Open Sans', sans-serif;
$font-family-bold: 'Gilroy-Bold', 'Open Sans', sans-serif;
$font-family-semi-bold: 'Gilroy-SemiBold', 'Open Sans', sans-serif;
$font-family-medium: 'Gilroy-Medium', 'Open Sans', sans-serif;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-bold: 600;

/* ==========================================================================
COLORS
========================================================================== */

$lighter-blue: #f7fafd;
$light-blue: #e6f0fa;
$ice-blue-1: #dbe0e5;
$ice-blue-2: #f0f2f4;
$input-border-color: #c8cfd6;
$lighter-gray: #f0f0f0;
$light-gray: #fafafa;

$_text-color-map: (
  gray-1: #96A3B0,
  gray-2: #68798A,
  gray-3: #3C4650,
  gray-4: #2D353D,
  gray-5: #1F2429,
  gray-6: #101316,
  gray-7: #C4C4C4
);
@function text-color($color-name) {
  @return map-get($_text-color-map, $color-name);
}

// Private variables.
// Use the color() function to access theme.
$_primary: #EB002D;
$_primary-light: #FF1A47;
$_primary-dark: #D20014;
$_primary-contrast: #fff;

$_secondary: #5a0037;
$_secondary-light: #741A51;
$_secondary-dark: #41001E;
$_secondary-contrast: #fff;

$_success: #0F9B5F;
$_warning: #FF7800;
$_error: #EB002D;

$_light: #b4bec8;
$_dark: #3c4650;

$_color-map: (
  primary: (
    base: $_primary,
    light: $_primary-light,
    dark: $_primary-dark,
    contrast: $_primary-contrast
  ),
  secondary: (
    base: $_secondary,
    light: $_secondary-light,
    dark: $_secondary-dark,
    contrast: $_secondary-contrast
  ),
  success: (
    base: $_success,
    light: lighten($_success, 15%),
    dark: darken($_success, 15%),
    contrast: #fff
  ),
  warning: (
    base: $_warning,
    light: lighten($_warning, 15%),
    dark: darken($_warning, 15%),
    contrast: #fff
  ),
  error: (
    base: $_error,
    light: lighten($_error, 15%),
    dark: darken($_error, 15%),
    contrast: #fff
  ),
  light: (
    base: $_light,
    light: lighten($_light, 15%),
    dark: darken($_light, 15%),
    contrast: #000
  ),
  dark: (
    base: $_dark,
    light: lighten($_dark, 15%),
    dark: darken($_dark, 15%),
    contrast: #ffffff
  )
);

// Mixin to access color scheme variables
// Usage: color: colors(primary) or colors(primary, light)
@function color($color-name, $tone: 'base') {
  @return map-get(map-get($_color-map, $color-name), $tone);
}

$mpm-red: $_primary;
$mpm-red-light: #F9B2C0;
$mpm-blue: $light-blue;
$mpm-bluer: #d0e2f2;
$mpm-plum1: $_secondary;
$mpm-plum2: $_secondary-light;

$disabledGrey: rgba(0, 0, 0, 0.38);

/* ==========================================================================
SCROLLBAR
========================================================================== */

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.viewRoot {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.viewport {
  flex-grow: 1;
  min-height: 0;
  overflow: auto;
}
.viewContainer {
  flex-grow: 1;
  min-height: 0;
  overflow: auto;
  height: 100%;
}
._directionCol {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  padding: 4px;
  & > ._directionCol, & > ._directionRow {
    padding: 0;
  }
}
._directionRow {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow: auto;
  width: 100%;
  padding: 4px;
  & > ._directionCol, & > ._directionRow {
    padding: 0;
  }
}
._growRelative {
  order: 0;
  flex-grow: 1;
  position: relative;
  ._fillRelative {
    height: 100%;
    width: 100%;
    position: absolute;
  }
}
._fullHeight {
  height: 100%;
}
._fullWidth {
  width: 100%;
}
._halfWidth {
  width: 50%;
}
._scrollY {
  overflow-y: auto;
}
._centerY {
  display: flex;
  justify-content: center;
}
._centerX {
  display: flex;
  align-items: center;
}
._bold {
  font-family: $font-family-bold;
}

._header {
  font-size: 1.17em;
  font-family: $font-family-bold;
  margin: 8px 0;
  color: $_secondary;
}

._filterItem{
  margin-bottom: 16px;
}
._formRow {
  display: flex;
  margin: 8px 0 16px 0;
  &>div {
    flex-shrink: 0;
    width: 100%;
  }
}
._formRowDouble {
  display: flex;
  margin: 8px 0 16px 0;
  &>div {
    flex-shrink: 0;
    width: 50%;
  }
  &>div:first-child {
    padding-right: 8px;
  }
  &>div:last-child {
    padding-left: 0;
    margin-left: 8px;
    padding-right: 8px;
  }
  &>div:only-child {
    padding-left: 0;
    margin-left: 0;
  }
}
._formRowDouble.noMargins {
  margin: 0;
}
._formRowDouble:last-of-type {
  margin-bottom: 8px!important;
}
._formRowDouble:last-of-type.noMargins {
  margin-bottom: 0!important;
}
._aboveDrawer {
  z-index: 1201;
}
._fullScreenDrawer {
  width: 100%;
  .MuiDrawer-paper{
    width: 100%;
    background: $mpm-blue;
  }
}
._dialogOnDescribeFrameView {
  width: 67%;
  margin-left: 33%;
  .MuiBackdrop-root {
    display: none;
  }
}
._dialog-likeView {
  // this style full-screen dialog to look similar to regular view
  // need to add _directionRow or _directionCol to DialogContent to align paddings
  .MuiDialogContent-root {
    padding: 4px;
    background: $mpm-blue;
    .footerRoot {
      margin-bottom: -4px;
      margin-left: -4px;
      margin-right: -4px;
    }
  }
  .MuiDialogActions-root {
    padding: 5px;
  }
  .MuiDialogTitle-root {
    background: $mpm-blue;
  }
}

._disabled {
  color: $disabledGrey;
}
.disabledLink {
  pointer-events: none;
}

.clearInputButtonContainer {
  display: flex;
  align-items: center;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

//material overrides
.paperXRoot .paperX {
  .MuiFormHelperText-root {
    color: text-color(gray-2);
  }
  .MuiFormHelperText-root.Mui-error {
    color: $mpm-red;
  }
  .MuiFormHelperText-root.Mui-disabled {
    color: $disabledGrey;
  }
}
.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    background: $mpm-red;
  }
}
.MuiTabs-root {
  .MuiTab-textColorPrimary {
    color: $mpm-plum1;
  }
}
.MuiButton-endIcon svg {
  transition: none; // solving issue: endIcon of a button changing color too late when switching disable property
}
  // DialogTitle
.MuiTypography-h6.MuiDialogTitle-root {
  color: $mpm-plum1;
}

a:link {
  text-decoration: none;
  color: $_secondary
  }
  