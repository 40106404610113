.footerRoot {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  .actionsLeft{
    &>*{
      margin-right: 8px;
    }
  }
  .actionsRight{
    &>*{
      margin-left: 8px;
    }
  }
}