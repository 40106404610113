@import 'src/styles/shared';

.generalInfoDisplayRoot {
  .infoChip {
    background-color: $mpm-plum2;
  }
  .infoChip2 {
    background-color: $mpm-blue;
    color: $mpm-plum1;
  }
  .infoChip3 {
    background-color: $mpm-red-light;
    color: $mpm-plum1;
  }
}