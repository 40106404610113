.spotsOverviewFiltersRoot {
  display: grid;
  align-items: stretch;
  align-content: stretch;
  justify-content: stretch;
  justify-items: stretch;
  grid-template-columns: minmax(200px, 45%) minmax(360px, 550px) 540px;
  grid-auto-flow: row dense;
  gap: 24px;
  .validityMediumTypeContainer {
    display: flex;
  }
  .validitySelectorContainer {
    margin-right: 24px;
    min-width: 310px;
  }
  .mediumTypeSelectorContainer {
    max-width: 300px;
  }
  .MuiOutlinedInput-root {
    width: 100%;
  }
  .countryBannerSelectorRoot {
    width: 100%;
  }
  .numInput {
    width: 200px;
  }
}
@media (max-width: 1180px) {
  .spotsOverviewFiltersRoot {
    grid-template-columns: repeat(auto-fill, minmax(min(200px, 25%), calc(50% - 12px)));
  }
}
@media (max-width: 560px) {
  .spotsOverviewFiltersRoot {
    grid-template-columns: repeat(auto-fill, minmax(min(200px, 25%), calc(100% - 12px)));
  }
  .validityMediumTypeContainer {
    flex-direction: column;
  }
  .validitySelectorContainer {
    margin-bottom: 24px;
  }
}