.leafletBrowserRoot{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .leafletFileNameLabel {
    font-size: 0.9em;
    width: 100%;
    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: 0;
      max-width: 90%;
    }
  }
}