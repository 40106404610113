@import 'src/styles/shared';

.mediaDescriptionRoot {
  .viewport {
    display: flex;
  }
  ._growRelative {
    display: grid;
  }
  .leftCol {
    width: 33%;
    .audiofileRow {
      margin-top: 0;
      margin-bottom: 0;
      .mediaAudiofileRoot {
        border: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
    .transcriptionReadContainer {
      border-radius: 4px;
      padding: 8px 16px;
      margin-bottom: 8px;
      .transcriptValue {
        max-height: calc(100% - 32px);
        overflow: auto;
        white-space: pre-wrap;
      }
    }
  }
  .advertisementContainer {
    width: 67%;
  }
}