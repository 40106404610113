.countrySelectorRoot {
  .countrySelect {
    width: 100%;
  }
}

.countrySelectorRootCompact {
  .countrySelect {
    min-width: 72px;
  }
}