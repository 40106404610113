@import 'src/styles/shared';

.summaryCardRoot {
  padding: 4px;
  .summaryCardContainer {
    display: flex;
    background: white;
    border-radius: 8px;
    .summaryRow {
      padding: 4px 0;
    }
    .labels {
      padding: 8px 16px 8px 8px;
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
      border-right: 1px solid $mpm-red;
      flex-shrink: 0;
      .summaryRow {
        color: $mpm-red;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      .summaryRow.checkbox {
        height: 42px;
      }
    }
    .values {
      padding: 8px;
      width: 100%;
      .summaryRow{
        padding-left: 12px;
      }
      .summaryRow.checkbox {
        padding: 0;
      }
    }
  }
}