.franchiseOwnerRoot {
  .inputsContainer {
    .retailerWithCountry {
      width: 50%;
      margin-bottom: 16px;
      display: flex;
      justify-content: stretch;
      align-items: flex-end;
      .retailerContainer {
        width: 100%;
      }
    }
  }
}