.BPCODescriptionRoot {
    .descriptionContainer {
      .deleteBtn {
        .MuiButtonBase-root {
          margin-top: 18px;
          margin-left: 34px;
        }
      }
    }
    .addBtn {
      margin-bottom: 24px;
    } 
    .alternativeDescriptionTitle {      
      &+.addBtn {
          margin-top: 12px;
      }
    } 
  }