div.SnackbarContainer-root {
  .SnackbarItem-variantError, .SnackbarItem-variantSuccess, .SnackbarItem-variantWarning, .SnackbarItem-variantInfo{
    color: #3C4650;
    background: #fff;
    padding: 0;
    padding-left: 16px;
    border-radius: 16px!important;
    box-shadow: 0 25px 50px -12px #1e212540;
    overflow: hidden;
  }
  .SnackbarItem-variantError {
    .SnackbarItem-message .MuiSvgIcon-root {
      color: #EB002D
    }
  }
  .SnackbarItem-variantSuccess {
    .SnackbarItem-message .MuiSvgIcon-root {
      color: #0F9B5F
    }
  }
  .SnackbarItem-variantWarning {
    .SnackbarItem-message .MuiSvgIcon-root {
      color: #FF7800;
    }
  }
  .SnackbarItem-variantInfo {
    .SnackbarItem-message .MuiSvgIcon-root {
      color: #d0e2f2;
    }
  }
}