@import 'src/styles/shared';

.iconTileRoot {
  align-items: center;
  background: #fff;
  cursor: pointer;
  display: flex;
  height: 150px;
  justify-content: center;
  padding: 0 16px;

  &:hover {
    .title {
      color: red;
    }
  }

  &.disabled {
    cursor: default;
    filter: grayscale(1);
  }

  .content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .icon {
    font-size: 5rem;
    line-height: 0;
    width: 106px;
    height: 106px;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    align-items: center;
    display: flex;
    svg {
      width: 60px;
      height: 60px;
    }
  }

  .title {
    font-size: 14px;
    line-height: 1.14;
    width: 106px;
    max-height: 88px;
    color: $mpm-plum1;
    text-align: center;
    white-space: pre-line;
  }
}
