@import 'src/styles/shared';

.promotionsMultiSuggestionRoot {
  .promotionsBtnContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    .MuiButton-root:not(:last-child) {
      margin-right: 8px;
    }
    .MuiCheckbox-root {
      padding: 0;
    }
  }
  .promotionsListDetails {
    cursor: pointer;
    .framePreviewRoot {
      height: 300px;
    }
  }
  &.promotionSelected {
    .paperX {
      border: 2px dashed $mpm-bluer;
      opacity: 0.7;
    }
  }
}
