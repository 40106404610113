@import 'src/styles/shared';

.frameLensZoomContainer {
    position: relative;
    display: inline-block;
    height: 100%;
    width: 100%;
    
    img {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 100%;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  
    .lens {
      position: absolute;
      border: 1px solid $_secondary-dark;
      width: 100px; // lens width
      height: 100px; // lens height
      cursor: none;
    }
}