@import 'src/styles/shared';

.leafletPageContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  overflow: hidden;
  cursor: grab;
  &.canvasBackground {
    background: $mpm-blue;
  }
  img {
    height: 100%;
    max-width: 100%;
    object-fit: scale-down;
  }
  .hidden{
    display: none;
  }
  .errorMsg {
    position: absolute;
    top: 50%;
  }
}