@import 'src/styles/shared';

.productBpcoDisplayRoot {

  .characteristics{
    font-size: 0.8em;
  }
  ._directionCol.productBpcoColumn {
    display: flex;
    padding: 0 6px;
    ._formRowDouble {
      flex-wrap: wrap;
      gap: 12px;
      &>div {
        padding-right: 0;
      }
      &>div:last-child {
        margin-left: 0;
      }
    }
  }
  ._directionCol.productBpcoColumn {
    padding-top: 4px;
  }
  .productBpcoPropertyRow {
    display: flex;
    padding-bottom: 4px;
    font-size: 0.9em;
  }
  .productBpcoPropertyCol {
    font-family: $font-family-bold;
    display: flex;
    align-items: center;
    width: 100%;
    overflow-wrap: anywhere;
  }
  .productBpcoPropertyLabel {
    font-family: $font-family-regular;
    padding-right: 4px;
  }
}