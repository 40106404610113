@import 'src/styles/shared';

.giveAwayBundlingDrawerRoot {
    background: rgba(0, 0, 0, 0.8);
    .MuiDrawer-paper {
        background: $mpm-blue;
        width: 67%;
    }
    .productSearchRoot {
        .apiSwitch {
            display: none;
        }
        .searchResultsContainer {
            left: 0;
            top: 148px;
        }
    }
}