@import 'src/styles/shared';

.attributeRoot {
  display: flex;
  .complexAttributeWrapper {
    border: none;
    width: 100%;
    display: flex;
    margin: 0;
    padding: 8px 0 0 0;
    legend {
      font-size: 0.8em;
    }
  }
  &.attributeRange, &.attributeEnum {
    .MuiTextField-root {
      margin-right: 8px;
    }
    .numberInput {
      max-width: 150px;
    }
    .attributeAutocomplete {
      width: 100%;
    }
  }
}
.attributeTextRoot {
  .attributeName {
    font-family: $font-family-bold;
  }
}
.deprecated:first-child {
  background: #96A3B020;
  color: $disabledGrey;
 }