.BPCOViewRoot {
  .descriptionContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .required {
      cursor: pointer;
      span {
        text-transform: lowercase;
      }
    }
    .BPCODescriptionDisplayRoot {
      width: 90%
    }
    button {
      width: 40px;
      height: 40px;
    }
  }
}