.validitySelectorRoot {
  width:100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .datepickerRoot {
    position: relative;
    width: 100%;
    .MuiFormControl-root {
      width: 100%;
    }
    .clearBtn {
      position: absolute;
      top: 8px;
      right: 42px;
      opacity: 0;
        &:hover {
        opacity: 1;
        }
      }
    }
  .datepickerRoot.left {
    padding-right: 8px;
  }
  &>.MuiButtonBase-root {
    margin-left: 8px;
  }
  &>.MuiTextField-root {
    margin-right: 8px;
  }
}