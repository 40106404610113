@import 'src/styles/shared';
.fileUploadRoot{

  .inputFile {
    display: none;
  }

  .dropZone {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
  }

  .fileUploadContainer {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 2px dashed $mpm-bluer;
    padding: 24px;
    flex-direction: column;
  }

  .withoutBorder {
    border: 0;
  }

  .fileUploadContent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &.disabled {
      filter: grayscale(1);
    }
  }

  .fileUploadIcon {
    color: $mpm-bluer;
    font-size: 120px;
    line-height: 0;
  }

  .fileUploadText {
    margin: 0 0 16px 0;
    font-size: 1.1rem;
    text-align: center;
    font-family: $font-family-bold;
  }

  .fileUploadTextOr {
    font-family: $font-family-regular;
  }

  .disabled {
    cursor: not-allowed;
  }

}