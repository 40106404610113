@import 'src/styles/shared';

.mediaAudiofileRoot {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid text-color(gray-7);
    padding: 16px;
    margin-bottom: 20px;
    .fileUploadIcon {
        color: $mpm-bluer;
        font-size: 60px;
        line-height: 0;
    }
    .fileUploadText {
        margin: 0 0 8px 0;
        font-size: 1rem;
        text-align: center;
        font-family: $font-family-bold;
    }
    .fileUploadTextDisabled {
        color: text-color(gray-7);
    }
    .fileUploadedName {
        align-self: flex-start;
        margin-top: 8px;
        .fileList {
            display: flex;
            align-items: center;
            height: 36px;
            font-weight: bold;
        }
    }
    video {
        width: 100%;
    }
}