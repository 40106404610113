@import 'src/styles/shared';

.statusDisplayErrorRoot, .statusDisplayLoadingRoot {
  height: 56px;
  padding: 8px;
  line-height: 34px;
  display: flex;
  align-items: center;
  svg {
    color: $mpm-red;
  }
}