.promotionsOverviewFiltersRoot {
  &>div {
    width: 25%;
    display: inline-block;
    padding-right: 8px;
  }
  .countryBannerSelectorRoot {
    width: 100%;
  }
  .numInput {
    width: 200px;
  }
  .searchByProductContainer {
    display: inline-flex;
  }
}