@import 'src/styles/shared';

@keyframes circleBounce {
  0%    { width: 220px; height: 220px }
  50%   { width: 300px; height: 300px }
  100%  { width: 220px; height: 220px }
}

.fileUploadOverlay {
  pointer-events: none;

  .backdrop {
    position: fixed;

    background: rgba(255, 255, 255, 0.7);
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 999;
  }

  .uploadOverlayCircleContainer {
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 300px;
    height: 300px;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    z-index: 1000;
  }

  .uploadOverlayCircle {
    animation-name: circleBounce;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    position: absolute;
    width: 300px;
    height: 300px;
    background: $mpm-red;
    border-radius: 50%;
  }

  .uploadOverlayIconContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -24px;
    z-index: 1;
  }

  .uploadOverlayIcon {
    font-size: 120px;
    line-height: 0;
  }

  .uploadOverlayTitle {
    margin: 0;
  }
}
