@import 'src/styles/shared';

.spotDescriptionRoot {
  .spotContainer {
    ._formRowDouble {
      width: 90%;
      .attributeRoot {
        .complexAttributeWrapper {
          padding-top: 0;
        }
      }
    }
    .transcriptionContainer {
      height: 30%;
    }
    .broadcastOverviewNoShowView {
      display: flex;
      flex-direction: column;
      border-radius: 4px;
      border: 1px solid text-color(gray-7);
      padding: 16px !important;
    }
    .broadcastOverviewView {
      height: 65%;
      display: flex;
      flex-direction: column;
      border-radius: 4px;
      border: 1px solid text-color(gray-7);
      padding: 16px !important;
    }
    .broadcastOverviewTitle {
      margin: 0;
      font-size: 1.1rem;
      font-family: $font-family-bold;
    }
    .broadcastOverviewInformation {
      font-size: 1.0rem;
      font-family: $font-family-regular;
    }
  }
  .countryBannerSelector {
    width: 50%;
    display: flex;
    justify-content: stretch;
    align-items: flex-end;
    .countrySelector {
      margin-right: 8px;
    }
  }
}