@import 'src/styles/shared';

.bannerSelectorOption.selected{
  background-color: lighten($mpm-red, 50%);
  color: $mpm-red;
}
.bannerSelectorOption {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 4px;
  cursor: pointer;
  &:hover {
    background-color: $mpm-blue;
  }
  .bannerName {
    margin-right: 32px;
  }
}