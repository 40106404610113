.promotionsSuggestionRoot {
  .promotionsBtnContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    .MuiButton-root:not(:last-child) {
      margin-right: 8px;
    }
  }
  .promotionsListDetails {
    cursor: pointer;
    .framePreviewRoot {
      height: 300px;
    }
  }
}
