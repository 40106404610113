.BPCODescriptionDisplayRoot {
  .description {
    max-width: 999px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }
  &.expanded {
     .description {
       white-space: normal;
     }
   }
}