@import 'src/styles/shared';

.productPreviewRoot {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 300px;
    width: 100%;
    background-color: #fff;
    .imagesContainer {
        position: relative;
        width: 900px;
        height: 100%;
        display: flex;
        margin-right: 8px;
        background: $ice-blue-1;
        .infoLabel {
            width: 100%;
        }
        div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 33.3%;
            margin-right: 10px;
            &:last-of-type {
                margin-right: 0;
            }
            img {
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
                cursor: zoom-in;
            }
        }
        .loadingOverlayContainer {
            position: absolute;
            width: 100%;
        }
    }
}