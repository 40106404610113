.productLineListRoot {
  .productLineListIndexRoot {
    flex:1;
    min-width: 400px;
    .paperX{
      overflow: hidden;
    }
  }
  .spacer {
    height: 18px;
  }
}