@import 'src/styles/shared';

.navigationRoot {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 48px;

  .card{
    border-radius: 20px!important;
    box-shadow: none;
    margin: 20px;
    .cardHeader{
      padding-bottom: 0;
      span {
        color: $_secondary;
      }
    }
  }

  .cardContent {
    flex-direction: row;
    display: flex;
    //justify-content: center;
    align-items: baseline;
  }
}