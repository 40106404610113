@import 'src/styles/shared';

.brickAttributeDialogRoot {
  .MuiDialogContent-root {
    padding: 16px 8px;
  }
  .literalsList .MuiButton-root {
    margin: 0 8px 8px 0;
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .literalsList {
    margin-bottom: 16px;
    .deleteBtn {
      color: rgba($_secondary, 0.7);
      margin-right: -10px;
      &:hover {
        color: $_secondary;
      }
    }
    .brickAttributeBtn {
      span {
        padding-right: 8px;
      }
    }
  }
}