@import 'src/styles/shared';

.franchiseOwnersRoot {
  .inputsContainer {
    .retailerWithCountry {
      width: 50%;
      display: flex;
      justify-content: stretch;
      align-items: flex-end;
      .retailerContainer {
        width: 100%;
      }
    }
  }
  .ownersContainer {
    flex:1;
    min-width: 400px;
    .paperX{
      overflow: hidden;
    }
    .ownersListContainer {
      margin-top: 8px;
      overflow: scroll;
      height: 100%;
    }
  }
}