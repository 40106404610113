@import 'src/styles/shared';

.summaryCardRoot.categoryAttributeCardRoot {
  .values {
    cursor: pointer;
  }
  .literalsMissingWarning {
    text-align: center;
    color: $mpm-red;
    font-family: $font-family-bold;
  }
}
.summaryCardRoot.categoryAttributeCardRoot.expanded {
  .values {
    cursor: default;
  }
}