@import 'src/styles/shared';

.bannerSelectorRoot {
  position: relative;
  .bannerSelectorOption.selected{
    background-color: lighten($mpm-red, 50%);
    color: $mpm-red;
  }
  .bannerSelectorOption {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 4px;
    cursor: pointer;
    &:hover {
      background-color: $mpm-blue;
    }
    .bannerName {
      margin-right: 32px;
    }
  }
  .bannerSelectorInput.loading {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: white;
    border-radius: 4px;
    border: 1px solid silver;
    color: silver;
  }
}