.productLineRoot {
  .activePosition {
    padding-left: 32px;
    margin: auto;
  }
  .productsFiltersContainer {
    width: 300px;
    height: 100%;
    overflow: scroll;
  }
}