@import 'src/styles/shared';

.actionListCard {
  padding: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 8px;
  .actionListCardLabel {
    flex: 1;
  }
}
.actionListCard.selected, .actionListCard:hover {
  background: $mpm-blue;
}