@import 'src/styles/shared';

.frameDetailsRoot {
  h3 {
    margin-top: 0px;
  }
  .divider {
    border-top: 1px solid $mpm-red;
    margin: 8px -8px;
  }
  .sectionTitle {
    padding-top: 8px;
  }
  div {
    .giveAwayBundlingTooltip {
      display: inline;
    }
  }
}
