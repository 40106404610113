@import 'src/styles/shared';

.leafletDescriptionRoot {
  .branchesRow {
    display: flex;
    align-items: center;
    button {
      margin-left: 16px;
    }
    div {
      display: inline;
      font-size: 1.4em;
      span {
        margin: 0 8px;
      }
    }
  }
  .headerDataContainer{
    width: 70%;
    .attributePosition {
    margin-top: -8px;
    }
    .addPageTitle {
      margin-bottom: 0;
    }
    ._directionRow {
      padding-right: 0;
      ._directionCol {
        width: 100%;
        ._formRow {
          margin-bottom: 8px;
        }
        &:not(.pageLevelColumn) {
          padding-left: 8px;
        }
      }
      .pageLevelColumn {
        padding-right: 8px;
        ._formRowDouble {
          >div {
            width: 100%;
          }
        }
      }
    }
  }
  .leafletBrowserContainer {
    width: 30%;
    position: relative;
  }
  .countryBannerSelectorRoot {
    width: 50%;
    display: flex;
    justify-content: stretch;
    align-items: flex-end;
    .banner {
      width: 100%;
    }
  }
}
