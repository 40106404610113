.productBulkChangeFiltersRoot {
  display: flex;
  &>div {
    width: 33%;
    margin: 0 8px
  }
  &>div:first-child {
    margin-left: 0;
  }
  &>div:last-child {
    margin-right: 0;
  }
  .searchWithReload {
    display: flex;
    align-items: center;
  }
  &.noBrick {
    &>div {
      width: 50%;
    }
  }
}