@import 'src/styles/shared';

.gtinDetailsDialogRoot {
  .gtinInputContainer{
    padding: 8px;
  }
  .gtinAlreadyUsedErr {
    color: $mpm-red;
    font-size: 0.9em;
    margin-right: 16px;
  }
}