@import 'src/styles/shared';

.leafletUploadCardRoot {
  position: relative;
  .detailsContainer {
    display: flex;
    flex-direction: row;
  }
  .labels{
    flex-grow: unset;
  }
  .details, .labels {
    padding: 8px;
  }
  .status {
    padding: 0 16px;
    display: flex;
    align-items: center;
  }
  & ._directionRow{
    padding-bottom: 0;
  }
  .actionButtons{
    padding-right: 8px;
    display: flex;
    align-items: center;
    width: 85px;
    justify-content: flex-end;
    button {
      margin-left: 8px;
    }
  }
  .MuiLinearProgress-root {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}