@import 'src/styles/shared';

.logoRoot {
  cursor: default;
  user-select: none;

  .logoTop {
    fill: $mpm-red;
    font-family: $font-family-bold;
    font-size: 30px;
  }

  .logoBottom {
    fill: $mpm-plum2;
    font-family: $font-family-regular;
    font-size: 30px;
  }
}
