@import 'src/styles/shared';

.mediumProposalsRoot {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    border: 1px solid text-color(gray-7);
    padding: 16px 0 16px 16px !important;
    margin-bottom: 16px;
        .mediumProposalsTitle {
            margin: 0 0 16px 0;
            font-size: 1.1rem;
            font-family: $font-family-bold;
        }
        .mediumProposalsContainer {
            max-height: 300px;
            overflow-y: auto;
            padding-right: 16px;
            .mediumProposalItem {
                width: 100%;
                padding: 8px;
                border: 2px dashed $mpm-bluer;
                &:hover {
                    cursor: pointer;
                }
                &:not(:last-child) {
                    margin-bottom: 16px;
                }
            }
        }

    }
