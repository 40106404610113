@import 'src/styles/shared';

.fileUploadDialogRoot{
  .MuiDialogContent-root {
    padding-left: 8px;
  }
  .MuiDialogContent-root:first-child{
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
  .MuiDialogContent-root{
    display: flex;
    background: $mpm-blue;
    .uploadContainer {
      padding-left: 4px;
      padding-top: 4px;
      padding-bottom: 4px;
    }
    .uploadContainer, .uploadListContainer{
      width: 50%;
    }
    .uploadListContainer {
      padding-left: 8px;
      padding-right: 4px;
      padding-top: 4px;
      li {
        margin-bottom: 8px;
      }
    }
  }
}