.descriptionInputRoot {
  width: 100%;
  margin-bottom: 0;
  .languageSelector {
    width: 25%
  }
  .description {
    margin-left: 16px;
    width: 75%
  }
  .clearBtn {
    margin-top: 8px;
  }
}