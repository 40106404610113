@import '../../../node_modules/ag-grid-community/src/styles/ag-grid.scss';
@import '../../../node_modules/ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material-mixin.scss';
@import 'src/styles/shared';

.ag-theme-material {

  // Theme properties

  @include ag-theme-material((
          data-color: $mpm-plum1,
          header-foreground-color: $mpm-plum1,
          row-hover-color: $mpm-blue,
          column-hover-color: $mpm-blue,
          secondary-foreground-color: $mpm-plum1,
          header-cell-hover-background-color: null,
          font-size: 1em,
          subheader-background-color: #fff
  ));

  // Override of ag-grid classes

  .ag-body-viewport {
    overflow-y: scroll !important;
  }

  .ag-react-container {
    height: 100%;
    width: 100%;
  }

  .ag-header-row {
    font-size: 0.9em;
  }

  .ag-header {
    border-top: 1px solid #e2e2e2;
  }

  .ag-header-cell {
    border-right: 1px solid #e2e2e2;
    padding-left: 0;
    padding-right: 0;
  }
  .ag-header-cell:last-child {
    border-right-color: rgba(1, 1, 1, 0);
  }

  .ag-header-cell-label {
    cursor: pointer;
    padding: 0 16px;
    height: 100%;
  }

  .ag-header-cell-text {
    white-space: normal;
  }

  .ag-cell-align-right {
    text-align: right;

    .ag-header-cell-text {
      text-align: right;
    }

    &.ag-header-cell,
    .ag-header-cell,
    .ag-header-cell-label {
      justify-content: flex-end;
    }
  }

  .ag-ltr .ag-header-cell-resize {
    right: 0;
  }

  .ag-cell {
    padding-left: 16px;
    padding-right: 16px;


    line-height: 39px;


  }

  .ag-row {
    animation: fadein .5s;
    cursor: pointer;
    font-size: 0.9em;
    height: 40px;
  }

  .ag-row-selected {
    background-color: $mpm-blue;
  }

  .ag-column-drop-title-bar {
    visibility: hidden;

    .ag-icon-group {
      margin-right: 12px;
    }
  }

  .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
    border-color: transparent;
  }

  .ag-ltr {
    .ag-group-contracted,
    .ag-group-expanded {
      margin-right: 4px;
    }
  }

  .ag-text-field-input {
    border: 1px solid rgba(0, 0, 0, 0.23)!important;
    padding: 8px;
    border-radius: 5px;
    margin: 0 16px!important;
  }

  .ag-text-field-input:hover {
    border: 1px solid black!important;
  }

  .ag-text-field-input:focus {
    border: 1px solid $mpm-plum1!important;
    border-width: 2px!important;
  }

  .ag-icon-tree-open,
  .ag-icon-tree-closed {
    padding: 4px;
    border-radius: 50%;
    transition: background-color .2s;

    &:hover {
      background-color: #fff;
    }
  }

  .ag-column-drop-wrapper {
    .ag-column-drop-list {
      position: relative;
      z-index: 1;

      &.ag-column-drop-horizontal-list {
        background: #fff;
      }
    }

    .ag-column-drop-horizontal {
      padding-left: 20px;
    }

    .ag-column-drop-empty-message {
      display: none;
    }

    .ag-column-drop-cell {
      background: #f2ebef;
    }

    .ag-column-drop-cell-drag-handle {
      margin-left: 8px;
    }

    .ag-column-drop-cell-button {
      margin: 0;
      min-width: 24px;
    }

    .ag-column-drop-horizontal-cell-separator {
      margin: 0 4px;
    }
  }

  &.ag-dnd-ghost {
    height: 32px !important;
    line-height: 32px !important;
    border-radius: 16px;
    z-index: 9999;
  }

  .ag-checkbox-input-wrapper.ag-checked::after {
    color: $mpm-red;
  }
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}


// custom styles

.ag-table-container {
  height: 100%;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  .ag-header {
    border-top-width: 0;
  }
}

//could be used on branches selector
// or branches selctor could use two regular 'papers'
//.ag-table-bordered-container {
//  .ag-table-container {
//    border-radius: 0;
//    .ag-header {
//      border-top-width: 1px;
//    }
//  }
//}

.ag-table-wrapper {
  height: 100%;
  width: 100%;
  .ag-cell-align-center, .ag-header-align-center {
    text-align: center;
    .ag-header-cell-text {
      margin: 0 auto;
    }
  }
  .active-flag-cell {
    display: flex;
    align-items: center;
  }
  .required-cell {
    background: $mpm-red-light;
  }
  ._highlightedTableRow {
    color: $mpm-red;
    background: $mpm-red-light;
  }
  ._disableTableRow {
    color: #96A3B0;
  }
  .ag-paging-panel {
    .pageSizeContainer {
      margin-right: 8px;
      .MuiFormControl-root {
        width: 160px;
      }
    }
  }
}

.ag-menu-list {
  .ag-menu-option:not(.ag-menu-option-disabled):hover {
    cursor: pointer;
  }
}

._customTooltipPositionFix {
  // @TODO this fixes cell tooltip layout, however column menu is then somewhat broken, dont use both features in same table until solved
  // @also context menu was affected but has been fixed with 'has' selector, can check if column menu can be handled same way
  .ag-theme-material.ag-popup:has(.productSearchResultsTooltipRoot) {
    position: absolute;
  }
}

._selectAllHeader {
  margin-left: 16px;
  margin-right: 0;
}