.productDescriptionRoot {
  height: 100%;
  width: 100%;
  .descriptionContainer{
    .frameDescription {
      &>div{
        margin-bottom: 8px;
      }
    }
    .deleteBtn {
      .MuiButtonBase-root{
        margin-top: 18px;
        margin-left: 32px;
      }
    }
  }
  .addBtn {
    .MuiButton-root{
      margin-top: 8px;
      margin-bottom: 12px;
    }
  }
  .spacer {
    height: 18px;
  }
}

