@import 'src/styles/shared';

.productSearchResultsTooltipRoot {
  background: white;
  border-radius: 2px;
  display: inline-block;
  padding: 8px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  ul {
    li {
      white-space: nowrap;
      color: text-color(gray-3);
      .attrName {
        font-family: $font-family-bold;
        margin-right: 8px;
      }
    }
  }
  .categoryName {
    white-space: nowrap;
  }
}
.productSearchResultsMUITooltipContentRoot {
  background: $mpm-blue;
  border-radius: 2px;
  display: inline-block;
  padding: 8px;
  margin-top: -4px;
  margin-left: -8px;
  margin-bottom: -4px;
  li {
    white-space: nowrap;
    color: text-color(gray-3);
    font-size: 16px;
    .attrName {
      font-family: $font-family-bold;
      margin-right: 8px;
    }
  }
  .categoryName {
    white-space: nowrap;
  }
}