@import 'src/styles/shared';

.framingContainerRoot {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  .pageContainer {
    flex: 0 1 100%;
    overflow: hidden;
    background: $ice-blue-1;
  }
  .frameActionsContainer {
    flex: 0 0 content;
    background-color: white;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    .advertisementSelector {
      margin-left: 8px;
    }
    div {
      display: flex;
      align-items: center;
      button {
        margin-left: 5px;
      }
    }
    .showLeafletCheckbox {
      margin-left: 8px;
    }
  }
}