@import 'src/styles/shared';

.productSearchRoot {
  .MuiTextField-root {
    width: 100%;
  }
  .searchInput {
    display: flex;
    height: 100%;
    width: 100%;
    .dividerV {
      width: 1px;
      margin: 0 8px;
    }
    .productSuggContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 250px;
    }
    .apiSwitch {
      margin-left: 8px;
    }
  }
  .searchResultsContainer {
    position: absolute;
    top: 80px;
    right: 0;
    bottom: 47px;
    left: 33%;
    z-index: 2;
    padding: 8px;
    background: $mpm-blue;
    overflow: scroll;
    .topBar {
      display: flex;
      justify-content: space-between;
      .focusModeSwitch {
        scale: 0.8;
        height: 16px;
      }
    }
  }
  .productSearchCardContainer {
    .productSearchCardRoot {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      border-right: 8px solid $mpm-plum1;
      &.highlight {
        background-color: lighten($mpm-red-light, 10%);
      }
    }
  }
  .productSearchCardContainer:nth-child(even) {
    .productSearchCardRoot {
      border-right: 8px solid $mpm-red;
    }
  }
}

.productSearchCardRoot {
  cursor: pointer;
  background: white;
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 8px;
  .attributesSpread {
    display: flex;
    justify-content: space-between;
    &>div{
      display: flex;
    }
    margin-bottom: 8px;
  }
  .productBpcoValue {
    margin-right: 16px;
  }
  .productBpcoValue.isTemplate {
    color: $mpm-red;
    white-space: nowrap;
  }
  .productLabel {
    min-width: 30%;
    display: inline-block;
  }
  .productLabel::after {
    content: ':';
    margin-right: 8px;
  }
  .characteristicsRoot {
    .characteristicItem {
      border: 1px solid white;
      width: 33%;
      display: inline-block;
      margin-bottom: 8px;
      .characteristic {
        display: flex;
        align-items: flex-end;
        .name {
          width: 30%;
          min-width: 30%;
        }
        .name::after {
          content: ':';
        }
        .value {
          margin-right: 8px;
        }
      }
      .characteristic.uniq {
        .name, .value {
          color: $mpm-red;
        }
      }
    }
    .characteristicItem:hover {
      border-color: $mpm-red-light;
      border-radius: 4px;
    }
  }
  .characteristicsRootSlim {
    .characteristicItem {
      display: inline-block;
      .characteristic {
        margin-right: 16px;
      }
      .characteristic.uniq {
        color: $mpm-red;
      }
    }
  }
}