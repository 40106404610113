@import 'src/styles/shared';

.promotionValidityRoot {
  display: flex;
  align-items: center;
  padding: 0 0 8px 12px;
  .value {
    text-transform: lowercase;
    .tooltip {
      cursor: pointer;
    }
  }
}