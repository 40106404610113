@import 'src/styles/shared';

.promotionsTableEditDrawerRoot {
  .MuiDrawer-paper {
    background: $mpm-blue;
    width: 67%;
  }
  .promotionRoot {
    .productColumn, .promoInvalidMsg {
      display: none;
    }
    .promotionActions button {
      display: none;
    }
    .promotionActions button.giveAwayBundlingBtn {
      display: block;
    }
  }
}