.MuiDialogContent-root {
    padding: 0;
}
.brandNewViewRoot {
    &.onDialogMode {
        .viewContainer {
            padding-top: 0;
            padding-right: 0;
            padding-left: 0;
        }
    }
}