.leafletsFiltersContainer {
  display: flex;
  flex-direction: column;
  .row {
    display: flex;
    align-items: flex-start;
    &>div {
      width: calc(100%/3);
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
    &>.numInput {
      width: 150px;
    }
  }
  &>:first-of-type {
    margin-bottom: 10px;
  }
  .complexAttributeWrapper {
    padding-top: 0;
  }
}