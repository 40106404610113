@import 'src/styles/shared';

.promotionsTableEraseDrawerRoot {
  .MuiDrawer-paper {
    background: $mpm-blue;
    width: 67%;
  }
  .MuiDivider-root {
    margin: 8px 0 16px 0;
  }
}