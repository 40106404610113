.headerRoot {
  align-items: center;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, .15) 0 2px 4px 0;
  display: flex;
  justify-content: space-between;
  padding: 16px 60px;

  .headerRight {
    align-items: center;
    display: flex;
  }
}