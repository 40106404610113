.myTeamsAdvertisementsFiltersContainers {
    display: flex;
    flex-direction: column;
    .row {
      display: flex;
      align-items: flex-start;
      &>div {
        width: 100%;
        &:not(:last-child) {
          margin-right: 8px;
        }
      }
      &>.numInput {
        width: 30%;
      }
      &>.userFilterFieldRoot {
        width: 200px;
      }
    }
  }