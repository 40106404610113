@font-face {
  font-family: 'Gilroy-Regular';
  src: local('Gilroy-Regular'), url(fonts/Gilroy-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Gilroy-Medium';
  src: local('Gilroy-Medium'), url(fonts/Gilroy-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'Gilroy-SemiBold';
  src: local('Gilroy-SemiBold'), url(fonts/Gilroy-SemiBold.otf) format('opentype');
}

@font-face {
  font-family: 'Gilroy-Bold';
  src: local('Gilroy-Bold'), url(fonts/Gilroy-Bold.otf) format('opentype');
}

html,
body,
#root {
  height: 100%;
}
