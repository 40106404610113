.leafletBrowserFullScreenRoot {
  width: 100%;
  height: 100%;
  padding: 0;
  .leafletIdLabel {
    position: absolute;
    top: 4px;
    background: white;
    padding: 4px 8px;
    font-size: 0.9em;
    border-radius: 10px;
    left: 4px;
  }
}