@import 'src/styles/shared';

.advertisementBrowserRoot {
  .leafletContainer {
    width: 33%;
  }
  .leaflet{
    height: 94%;
  }
  .validity {
    height: 6%;
  }
  .advertisementsContainer {
    width: 67%;
  }
  .frameValidity {
    padding-top: 8px;
    padding-left: 0;
    .btn {
      position: relative;
      color: $mpm-plum1;
      top: -8px;
      left: 4px;
    }
    .btn:disabled {
      color: $disabledGrey;
    }
  }
  .leafletPageContainer {
    z-index: 1201; //like in shared _aboveDrawer class
  }
}