@import 'src/styles/shared';

.spacer {
  height: 18px;
}
.isProductActive {
  margin-bottom: 20px;
}
.gtinValue {
  display: flex;
  align-items: center;
  margin: 20px 0 0 0;
  .MuiFormHelperText-root {
    position: absolute;
    top: 55px;
  }
}
.gtinIsActive {
  margin-top: 10px;
}
.separator{
  margin: 8px 0 0 0;
}

.productDetailsRoot {
  ._formRow {
    &:has(+ .isProductActive) {
      margin-bottom: 0px;
    }
  }
  .supplierRow {
    align-items: center;
  }
  .productReplacedMsgBox {
    background-color: $mpm-red-light;
    color: $mpm-red;
    padding: 8px;
    border-radius: 4px;
    button {
      font-family: $font-family-bold;
    }
  }
  .actionWrapper button {
    margin-right: 8px;
  }
  .imgSectionContainer {
    .productPreviewRoot {
      padding-top: 8px;
    }
  }
  .newBrandWrapper {
    margin-bottom: 24px;
    .newBrandBtn {
      margin-right: 8px;
    }
  }
}