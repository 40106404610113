@import 'src/styles/shared';

.fixReportedFramesDetailsDrawerRoot {
    .MuiDrawer-paper {
      background: $mpm-blue;
      width: 33%;
      height: calc(100% - 52px);
    }
    .frameValidity {
      padding-top: 8px;
      padding-left: 0;
      .btn {
        position: relative;
        color: $mpm-plum1;
        top: -8px;
        left: 4px;
      }
      .btn:disabled {
        color: $disabledGrey;
      }
    }
}