.frameValidityDialogRoot{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .datepickerRoot {
    position: relative;
    width: 100%;
    .clearBtn {
      position: relative;
      top: 8px;
      right: -12px;
    }
  }
  .datepickerRoot.bottom {
    padding-bottom: 8px;
  }
}

