@import 'src/styles/shared';

.priceDisplayRoot {
  .priceChip {
    background-color: $disabledGrey;
  }
  .priceChip2 {
    background-color: $_dark;
  }
}

