@import 'src/styles/shared';

.publicationOverviewRoot {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px;
    border: 1px solid text-color(gray-7);
    border-radius: 4px;
    &.isData {
      .ag-table-container {
        height: 400px;
        margin-top: 8px;
      }
    }
    .publicationOverviewTitle {
      font-family: $font-family-bold;
      font-size: 1.1rem;
      margin: 0;
    }
  }
  