.pageCountryThemedWeeksRoot {
  width: 100%;
  display: flex;
  align-items: center;
  .pageNumber {
    width: 18%;
    margin-top: 8px;
  }
  .countryThemedWeeksSelector {
    width: 100%;
    margin: 0 8px;
  }
  .deleteBtn  {
    margin-top: 8px;
  }
}