@import 'src/styles/shared';

.promotionsTableEraseRowRoot {
  display: flex;
  align-items: center;
  div {
    width: 25%;
    &.values {
      width: 50%;
    }
  }
  &:hover {
    background-color: $mpm-blue;
  }
}