.importGtinsRoot {
    display: inline;
    .fileInputRef {
        display: none;
    }
}
.importGtinsDialogRoot {
    .importGtinsSummaryList li {
        line-height: 1.8;
        .incorrectValues {
            margin-left: 20px;
        }
    }
}