.complexMDFilters {
  padding-right: 8px;
  padding-bottom: 8px;
  .MuiTextField-root {
    margin-bottom: 8px;
  }
  .filterActive {
    padding-left: 16px;
    padding-bottom: 8px;
  }
}