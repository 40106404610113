.pageSeasonThemeRoot {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .pageNumber {
    width: 18%
  }
  .seasonThemeRoot {
    position: relative;
    width: 100%;
    margin-top: -8px;
    margin-left: 8px;
    margin-right: 8px;
  }
}