@import 'src/styles/shared';

.categoryAttributesRoot {
  .summaryCardContainer .actions {
    flex-direction: column;
  }
  .summaryCardContainer:hover {
    background-color: $mpm-blue;
  }
  .smallAttrActionsContainer {
    display: flex;
    button {
      margin-left: 8px;
    }
    .MuiFormControlLabel-root {
      margin-right: 8px;
      white-space: nowrap;
    }
  }
}